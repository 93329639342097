import { ArmResource } from "./armResource";

export interface ViewableOption {
    key: string;
    displayName: string;
}

interface ArmResourceWithDisplayName extends ArmResource {
    name: string;
    properties: {
        displayName: string;
    };
}
export function toViewableOption(armResource: ArmResourceWithDisplayName): ViewableOption {
    return {
        key: armResource.name,
        displayName: armResource.properties.displayName,
    };
}