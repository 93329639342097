import * as Msal from "@azure/msal-browser";
import { IAuthenticator, AccessToken } from ".";
import { AadLoginRequest, SettingNames } from "../constants";
import { IEditorSettings } from "./IEditorSettings";
import { Logger } from "@paperbits/common/logging";

// const aadClientId = "a962e1ed-5694-4abe-9e9b-d08d35877efc"; // test app Current/Dogfood
// const aadClientId = "7ce72cae-9e4e-4536-8de9-2ca95f5fbd2a"; // PROD app

// const authority = "https://login.windows.net/72f988bf-86f1-41af-91ab-2d7cd011db47";

// login example
// http://localhost:8080?subscriptionId=b8ff56dc-3bc7-4174-a1e8-3726ab15d0e2&resourceGroupName=Admin-ResourceGroup&serviceName=igo-east

const ARM_TOKEN = "armAccessToken";
const TOKEN_REFRESH_BEFORE = 15 * 60 * 1000; // 15 min before token expiration

export class ArmAuthenticator implements IAuthenticator {
    private msalInstance: Msal.PublicClientApplication;
    private authPromise: Promise<AccessToken>;

    private readonly loginRequest: Msal.SilentRequest;

    constructor(
        private readonly editorSettings: IEditorSettings,
        private readonly logger: Logger
    ) {
        this.loginRequest = { ...AadLoginRequest, forceRefresh: true };
        this.refreshToken = this.refreshToken.bind(this);
        this.getAccount = this.getAccount.bind(this);
        this.acquireToken = this.acquireToken.bind(this);
        setInterval(() => this.refreshToken(), 5 * 60 * 1000); // check token expiration every 5 min
    }

    public get armEndpoint() {
        return this.editorSettings.editorArmEndpoint;
    }

    private async checkCallbacks(): Promise<Msal.AuthenticationResult> {
        try {
            return await this.msalInstance.handleRedirectPromise();
        }
        catch (error) {
            this.logger.trackError(error, { message: "Error on checkCallbacks." });
            return null;
        }
    }

    private async authenticate(): Promise<AccessToken> {
        const aadClientId = this.editorSettings.editorAadClientId;
        let authority = this.editorSettings.editorAadAuthority;
        const authTenantId = sessionStorage.getItem(SettingNames.authTenantId.toLowerCase());
        if (authTenantId) {
            authority = `https://login.windows.net/${authTenantId}`;
        }

        if (!aadClientId || !authority) {
            throw new Error("Settings was not provided for Msal.Configuration");
        }

        const redirectUri = location.origin;

        const msalConfig: Msal.Configuration = {
            auth: {
                clientId: aadClientId,
                authority: authority,
                redirectUri: redirectUri
            },
            cache: {
                cacheLocation: "sessionStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            }
        };

        this.msalInstance = new Msal.PublicClientApplication(msalConfig);

        const result = await this.acquireToken();

        return result;
    }

    private async refreshToken(): Promise<void> {
        const current = await this.getAccessToken();
        if (current.expiresInMs() < TOKEN_REFRESH_BEFORE) {
            await this.acquireToken();
            this.logger.trackEvent("ArmAuthenticator", { message: "Token refreshed." });
        }
    }

    private async acquireToken(): Promise<AccessToken|null> {
        const account = await this.getAccount();

        let authenticationResult: Msal.AuthenticationResult;

        if (account) {
            authenticationResult = await this.acquireTokenSilent(account);
        }
        else {
            authenticationResult = await this.checkCallbacks();
        }

        if (!authenticationResult) {
            await this.msalInstance.acquireTokenRedirect(this.loginRequest);
        }

        const accessToken =  AccessToken.parse(`${authenticationResult.tokenType} ${authenticationResult.accessToken}`);

        await this.setAccessToken(accessToken);

        return accessToken;
    }

    private async acquireTokenSilent(account: Msal.AccountInfo): Promise<Msal.AuthenticationResult> {
        try {
            this.msalInstance.setActiveAccount(account);
            const result = await this.msalInstance.acquireTokenSilent(this.loginRequest);

            return result;
        }
        catch (error) {
            this.logger.trackError(error, { message: "Error on acquireTokenSilent." });
            return null;
        }
    }

    private async getAccount(): Promise<Msal.AccountInfo> {
        if (!this.msalInstance) {
            await this.authenticate();
        }
        const accounts = this.msalInstance.getAllAccounts();

        if (accounts.length === 0) {
            return null;
        }

        return accounts[0];
    }

    public async getAccessToken(): Promise<AccessToken> {
        const accessTokenString = sessionStorage.getItem(ARM_TOKEN);

        if (accessTokenString) {
            return AccessToken.parse(accessTokenString);
        }

        if (this.authPromise) {
            return this.authPromise;
        }

        this.authPromise = this.authenticate();
        return this.authPromise;
    }

    public getStoredAccessToken(): AccessToken {
        const storedToken = sessionStorage.getItem(ARM_TOKEN);

        if (storedToken) {
            const accessToken = AccessToken.parse(storedToken);

            if (!accessToken.isExpired()) {
                return accessToken;
            } else {
                this.clearAccessToken();
            }
        }

        return null;
    }

    public async getAccessTokenAsString(): Promise<string> {
        const accessToken = await this.getAccessToken();
        return accessToken?.toString();
    }

    public async setAccessToken(accessToken: AccessToken): Promise<void> {
        if (accessToken.isExpired()) {
            this.logger.trackEvent("ArmAuthenticator", { message: "Cannot set expired access token." });
            return;
        }
        sessionStorage.setItem(ARM_TOKEN, accessToken.toString());
    }

    public clearAccessToken(): void {
        sessionStorage.removeItem(ARM_TOKEN);
    }

    public async isAuthenticated(): Promise<boolean> {
        const accessToken = await this.getAccessToken();

        if (!accessToken) {
            return false;
        }

        return !accessToken.isExpired();
    }
}