import { EventManager } from "@paperbits/common/events";
import { AccessToken } from "./../../authentication/accessToken";
import template from "./app.html";
import { ViewManager } from "@paperbits/common/ui";
import { Component, OnMounted } from "@paperbits/common/ko/decorators";
import { ISettingsProvider } from "@paperbits/common/configuration";
import { ISiteService } from "@paperbits/common/sites";
import { IAuthenticator } from "../../authentication";
import { AzureResourceManagementService } from "../../services/armService";
import { SessionManager } from "@paperbits/common/persistence/sessionManager";
import { DeveloperPortalType, SettingNames, WarningBackendUrlMissing, adminUserId } from "../../constants";
import { Logger } from "@paperbits/common/logging/logger";

const startupError = `Unable to start the portal`;

@Component({
    selector: "app",
    template: template
})
export class App {
    constructor(
        private readonly settingsProvider: ISettingsProvider,
        private readonly authenticator: IAuthenticator,
        private readonly viewManager: ViewManager,
        private readonly eventManager: EventManager,
        private readonly siteService: ISiteService,
        private readonly armService: AzureResourceManagementService,
        private readonly sessionManager: SessionManager,
        private readonly logger: Logger
    ) { }

    private async loadTenantSettingsForArm(): Promise<void> {
        const settings = await this.settingsProvider.getSettings();
        if (settings[SettingNames.aadClientId]) {
            try {
                await this.armService.loadSessionSettings(this.settingsProvider); // load tenant ARM parameters

                const managementApiUrl = await this.settingsProvider.getSetting<string>(SettingNames.managementApiUrl);
                if (!managementApiUrl) {
                    this.logger.trackDependency("loadTenantSettingsForArm", {message: "Warning: managementApiUrl missing"});
                    this.viewManager.addToast("Warning", WarningBackendUrlMissing);
                    return;
                }

                const userId = adminUserId; // Admin user ID for editor DataApi
                const userTokenValue = await this.armService.getUserAccessToken(userId, managementApiUrl);
                const serviceDescription = await this.armService.getServiceDescription(managementApiUrl);
                const dataApiUrl = serviceDescription.properties.dataApiUrl;
                const isMultitenant = serviceDescription.sku.name.includes("V2");

                const runtimeSettings = {
                    [SettingNames.dataApiUrl]: dataApiUrl,
                    [SettingNames.directDataApi]: true,
                    [SettingNames.managementApiAccessToken]: userTokenValue,
                    [SettingNames.isMultitenant]: isMultitenant
                };
                this.sessionManager.setItem("designTimeSettings", runtimeSettings);

                await this.settingsProvider.setSetting(SettingNames.dataApiUrl, dataApiUrl);
                await this.settingsProvider.setSetting(SettingNames.isMultitenant, isMultitenant);
                this.logger.trackMetric("loadTenantSettingsForArm", {message: `isMultitenant: ${isMultitenant}`});
            } catch (error) {
                this.viewManager.addToast(startupError, error);
                this.logger.trackError(error, {message: "Error loadTenantSettingsForArm"});
                return;
            }
        }
    }

    @OnMounted()
    public async initialize(): Promise<void> {
        let dataUrlSettingName = SettingNames.backendUrl;
               
        await this.loadTenantSettingsForArm();
        dataUrlSettingName = SettingNames.dataApiUrl;
          
        const settings = await this.settingsProvider.getSettings();

        if (!settings[dataUrlSettingName]) {
            const developerPortalType = settings[SettingNames.developerPortalType] || DeveloperPortalType.selfHosted;

            if (developerPortalType === DeveloperPortalType.selfHosted) {
                const toast = this.viewManager.notifyInfo("Settings", WarningBackendUrlMissing, [{
                    title: "Got it",
                    action: async () => this.viewManager.removeToast(toast)
                }]);
            }
        }

        try {
            const token = await this.authenticator.getAccessTokenAsString();

            if (!token) {
                const managementApiAccessToken = settings[SettingNames.managementApiAccessToken];

                if (!managementApiAccessToken) {
                    this.viewManager.addToast(startupError, `Management API access token is missing. See setting <i>managementApiAccessToken</i> in the configuration file <i>config.design.json</i>`);
                    return;
                }

                const accessToken = AccessToken.parse(managementApiAccessToken);
                const now = new Date();

                if (now >= accessToken.expires) {
                    this.viewManager.addToast(startupError, `Management API access token has expired. See setting <i>managementApiAccessToken</i> in the configuration file <i>config.design.json</i>`);
                    this.authenticator.clearAccessToken();
                    return;
                }

                await this.authenticator.setAccessToken(accessToken);
            }
        }
        catch (error) {
            this.viewManager.addToast(startupError, error);
            return;
        }

        try {
            /* Checking if settings were created, and if not, we consider the portal not initialized and launch setup dialog. */

            const siteSettings = await this.siteService.getSettings<any>();

            if (!siteSettings) {
                this.viewManager.setHost({ name: "setup-dialog" });
                return;
            }

            this.viewManager.setHost({ name: "page-host" });
            this.viewManager.showToolboxes();

            setTimeout(() => this.eventManager.dispatchEvent("displayHint", {
                key: "a69b",
                content: `When you're in the administrative view, you still can navigate any website hyperlink by clicking on it holding Ctrl (Windows) or ⌘ (Mac) key.`
            }), 5000);
        }
        catch (error) {
            this.viewManager.addToast(startupError, `Check if the settings specified in the configuration file <i>config.design.json</i> are correct or refer to the <a href="http://aka.ms/apimdocs/portal#faq" target="_blank">frequently asked questions</a>.`);
        }
    }
}