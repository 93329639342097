import * as ko from "knockout";
import template from "./pageDetails.html";
import { Component, Param, Event } from "@paperbits/common/ko/decorators";
import { PageItem } from "./pageItem";
import { IComponent, ViewManager } from "@paperbits/common/ui";
import { Access } from "../../contracts/accessContract";
import { IPageService } from "../../contracts/pageContract";
import { accessSelectorKey } from "../access/accessConstants";

type TabType = "design" | "access";
type TabTypeToComponent = {
    [k in TabType]: () => IComponent;
};

@Component({
    selector: "apim-page-details-workshop",
    template: template
})
export class PageDetailsWorkshop {

    @Param()
    public pageItem: PageItem;

    @Event()
    private readonly onDeleteCallback: () => void;

    @Event()
    private readonly onCopyCallback: (pageItem: PageItem) => void;

    public activeTabType = ko.observable<TabType>("design");
    public activeTabComponent = ko.pureComputed(() => this.tabTypeToComponent[this.activeTabType()]());

    private tabTypeToComponent: TabTypeToComponent = {
        design: this.getDesignTabComponent.bind(this),
        access: this.getAccessTabComponent.bind(this)
    };

    constructor(
        private readonly pageService: IPageService,
        private readonly viewManager: ViewManager
    ) {
    }

    public async setDesignTab() {
        this.activeTabType("design");
    }

    public async setAccessTab() {
        this.activeTabType("access");
    }

    private getDesignTabComponent(): IComponent {
        return {
            name: "page-design-properties-workshop",
            params: {
                pageItem: this.pageItem,
                onDeleteCallback: () => {
                    this.viewManager.notifySuccess("Pages", `Page "${this.pageItem.title()}" was deleted.`);
                    this.viewManager.closeWorkshop("page-details-workshop");
                    this.onDeleteCallback();
                },
                onCopyCallback: this.onCopyCallback
            }
        };
    }

    private getAccessTabComponent(): IComponent {
        return {
            name: accessSelectorKey,
            params: {
                header: "Configure who can see this page.",
                securityModel: this.pageItem.access(),
                onChange: async (contract: Access) => {
                    this.pageItem.access(contract);
                    await this.pageService.updatePage(this.pageItem.toContract());
                }
            }
        };
    }
}