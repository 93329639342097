import * as ko from "knockout";
import { ImpersonationService } from "../../services/impersonationService";
import { Access } from "../../contracts/accessContract";

export class DesignerAccessBindingHandler {
    constructor(
        private readonly impersonationService: ImpersonationService
    ) {
        ko.bindingHandlers["secured"] = {
            init: (element: HTMLElement, valueAccessor: any) => {
                const access: ko.Observable<Access> = valueAccessor();
                const initialAccess = ko.unwrap(access);

                const accessObservable: ko.Observable<Access> = ko.observable(initialAccess);
                const hiddenObservable: ko.Observable<boolean> = ko.observable(false);

                const applyAccess = (newAccess: Access) => {
                    accessObservable(newAccess);
                    void applyVisibility();
                };

                const applyVisibility = async () => {
                    const newAccess = accessObservable();
                    const visibleToUser = await this.impersonationService.hasAccessTo(newAccess);
                    hiddenObservable(!visibleToUser);
                };

                this.impersonationService.addImpersonationChangeListener(applyVisibility);

                ko.applyBindingsToNode(element, {
                    css: { hidden: hiddenObservable }
                }, null);

                ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                    this.impersonationService.removeImpersonationChangeListener(applyVisibility);
                });

                access.subscribe(applyAccess).disposeWhenNodeIsRemoved(element);
                applyAccess(initialAccess);
            }
        };
    }
}