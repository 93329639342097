import { IAuthenticator } from "./IAuthenticator";
import { HttpClient } from "@paperbits/common/http/httpClient";
import { Logger } from "@paperbits/common/logging";
import { ConfigEndpoints } from "../constants";
import { ArmAuthenticator } from "./armAuthenticator";
import { SsoAuthenticator } from "../components/ssoAuthenticator";
import { IEditorSettings } from "./IEditorSettings";

export class AuthenticatorResolver {
    private loadPromise: Promise<IAuthenticator>;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly logger: Logger) { }

    public async getAuthenticator(): Promise<IAuthenticator> {
        if (!this.loadPromise) {
            this.loadPromise = this.resolveAuthenticator();
        }
        return this.loadPromise;
    }

    public async resolveAuthenticator(): Promise<IAuthenticator> {
        const response = await this.httpClient.send<IEditorSettings>({ url: ConfigEndpoints.editor, method: "GET" });

        if (response.statusCode === 200) {
            const editorConfig = response.toObject();
            if (editorConfig.isArmAuthEnabled) {
                this.logger.trackEvent("AuthenticatorResolver", { message: "ArmAuthenticator resolved." });
                // For local/self hosted development get access token from config to session storage
                editorConfig["armAccessToken"] && sessionStorage.setItem("armAccessToken", editorConfig["armAccessToken"]);
                if (!editorConfig.editorArmEndpoint || !editorConfig.editorAadClientId || !editorConfig.editorAadAuthority) {
                    throw new Error("ARM authentication parameters are not defined.");
                }
                return new ArmAuthenticator(editorConfig, this.logger);
            }
        }
        this.logger.trackEvent("AuthenticatorResolver", { message: "SsoAuthenticator resolved." });

        return new SsoAuthenticator(this.httpClient, this.logger);
    }
}