import "./polyfills";
import * as ko from "knockout";
import { ISettingsProvider } from "@paperbits/common/configuration/ISettingsProvider";
import { GoogleFontsSettings } from "@paperbits/styles/workshops/googleFonts/googleFontsSettings";
import { InversifyInjector } from "@paperbits/common/injection";
import { OfflineModule } from "@paperbits/common/persistence/offline.module";
import { ApimStylesDesignModule } from "./styles/apimStylesDesignModule";
import { ApimDesignModule, ApimDesignerOverridesModule } from "./apim.design.module";
import { SsoAuthenticator } from "./components/ssoAuthenticator";
import { DeveloperPortalType, SettingNames } from "./constants";
import { SessionExpirationErrorHandler } from "./errors/sessionExpirationErrorHandler";
import { AppInsightsLogger } from "./logging/appInsightsLogger";
import { CoreDesignModule } from "@paperbits/core/core.design.module";
import { FormsDesignModule } from "@paperbits/forms/forms.design.module";
import { ReactModule } from "@paperbits/react/react.module";
import { ComponentBinder } from "@paperbits/common/editing";
import { LeftPanel } from "./admin/leftPanel";
import { RightPanel } from "./admin/rightPanel";
import { Logger } from "@paperbits/common/logging/logger";
import { MapiClient } from "./clients/mapiClient";
import { UnhandledErrorHandler } from "./errors/unhandledErrorHandler";
import { ClientLogger } from "./logging/clientLogger";
               
import { AuthenticatorResolver } from "./authentication/authenticatorResolver";
import { XmlHttpRequestClient } from "@paperbits/common/http/xmlHttpRequestClient";
import { ConsoleLogger } from "@paperbits/common/logging/consoleLogger";
          

const injector = new InversifyInjector();

async function startApp() {
    /* Initializing dependency injection container */
    injector.bindToCollection("autostart", SessionExpirationErrorHandler);
    injector.bindToCollection("autostart", UnhandledErrorHandler);
               
    injector.bindSingleton("httpClient", XmlHttpRequestClient);
    injector.bindSingleton("logger", ConsoleLogger);
    injector.bindSingleton("authenticatorResolver", AuthenticatorResolver);
    const authenticatorResolver = injector.resolve<AuthenticatorResolver>("authenticatorResolver");
    const authenticator = await authenticatorResolver.resolveAuthenticator();
    console.log("Resolved authenticator...");
    injector.bindInstance("authenticator", authenticator);
         
                                                              
          
    injector.bindModule(new CoreDesignModule());
    injector.bindModule(new ApimStylesDesignModule());
    injector.bindModule(new FormsDesignModule());

    injector.bindSingleton("apiClient", MapiClient);
    injector.bindModule(new ApimDesignModule());
    injector.bindModule(new ReactModule());
    injector.bindModule(new OfflineModule({ autosave: false }));
    // Should be set only after OfflineModule
    injector.bindModule(new ApimDesignerOverridesModule());

              
                                                   
         
    injector.bindInstance("logger", new AppInsightsLogger("4489976c-5bb8-4ff4-b071-6d5196917dfa"));
          

    const settingsProvider = injector.resolve<ISettingsProvider>("settingsProvider");
    if (settingsProvider) {
        await settingsProvider.setSetting(SettingNames.developerPortalType, DeveloperPortalType.managed);
        if (process.env.GoogleFontsApiKey) {
            await settingsProvider.setSetting<GoogleFontsSettings>("integration/googleFonts", { apiKey: process.env.GoogleFontsApiKey, apiUrl: null });
        }
    }
}

startApp()
    .then(() => {
        injector.resolve("autostart");

        /* Bootstrapping the application */
        if (document.readyState === "complete") {
            applyBindings();
        } else {
            document.addEventListener("DOMContentLoaded", () => {
                applyBindings();
            });
        }
        const logger = injector.resolve<Logger>("logger");
        logger.trackEvent("App", { message: "App starting..." });
    })
    .catch((error) => {
        const logger = injector.resolve<Logger>("logger");
        logger.trackError(error, { message: "App failed to start." });
    });

function applyBindings(): void {
    setImmediate(() => ko.applyBindings(undefined, document.body));

    // Binding the React app to element along with container
    const componentBinder = injector.resolve<ComponentBinder>("reactComponentBinder");
    const leftPanel = document.getElementById("admin-left-panel");
    componentBinder.bind(leftPanel, LeftPanel);
    const rightPanel = document.getElementById("admin-right-panel");
    componentBinder.bind(rightPanel, RightPanel);
}