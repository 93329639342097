export type AccessType = "group" | "api" | "product";

/**
 * Object which contains a definition of access privileges
 */
export interface AccessContract {
    /**
     * Type of entity
     */
    type: AccessType;

    /**
     * List of entities ids which are allowed
     */
    allow: string[];
}

export const EveryoneAccess: undefined = undefined;

export type Access = AccessContract | typeof EveryoneAccess;