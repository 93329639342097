import { IInjector, IInjectorModule } from "@paperbits/common/injection";
import { AccessSecurityModelEditor } from "../components/access/accessSecurityModelEditor";
import { AccessSecurityModelBinder } from "./accessSecutityModelBinder";
import { ImpersonationSelector } from "../components/access/impersonationSelector";
import { ImpersonationToolButton } from "../components/access/impersonationToolButton";
import { DesignerAccessBindingHandler } from "../bindingHandlers/access/designerAccessBindingHandler";
import { AccessPopupSecurityModelEditorProvider } from "../components/access/accessPopupSecurityModelEditorProvider";

export class AccessDesignModule implements IInjectorModule {
    public register(injector: IInjector): void {
        injector.bindToCollection("autostart", DesignerAccessBindingHandler);

        injector.bind("accessSecurityModelEditor", AccessSecurityModelEditor);
        injector.bindSingleton("securityModelBinder", AccessSecurityModelBinder);
        injector.bind("impersonationSelector", ImpersonationSelector);

        injector.bindToCollection("trayCommands", ImpersonationToolButton);
        injector.bind("visibilityCommandProvider", AccessPopupSecurityModelEditorProvider);
    }
}
