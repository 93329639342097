import * as Constants from "../constants";
import { MapiClient } from "../clients";
import { Utils } from "../utils";
import type { Page as PaperbitsPage } from "@paperbits/common/persistence/query";
import type { Page as PageDTO } from "../models/page";
import { ArmResource } from "../contracts/armResource";
import { toViewableOption, ViewableOption } from "../contracts/viewableOption";

interface ArmAccessResource extends ArmResource {
    properties: {
        displayName: string;
    };
}

export interface Page<T> extends PaperbitsPage<T> {
    count: number;
    takeNext?(): Promise<Page<T>>;
}

export class AccessValuesService {
    constructor(private readonly apiClient: MapiClient) { }

    getApis(searchPattern: string): Promise<Page<ViewableOption>> {
        return this.getElements("/apis", containsPattern(searchPattern), "getApisPage");
    }

    getProducts(searchPattern: string): Promise<Page<ViewableOption>> {
        return this.getElements("/products", containsPattern(searchPattern), "getProductsPage");
    }
    
    getGroups(searchPattern: string): Promise<Page<ViewableOption>> {
        const patternPart = containsPattern(searchPattern);
        const filter = `name ne 'administrators'${patternPart ? ` and ${patternPart}` : ""}`;
        return this.getElements("/groups", filter, "getGroupsPage");
    }

    private async getElements(baseUrl: string, filterExpression: string | undefined, portalHeader: string) {
        const params = {
            ["$skip"]: "0",
            ["$top"]: `${Constants.defaultPageSize}`,
            ["skipWorkspaces"]: "true"
        };
        if (filterExpression) {
            params["$filter"] = filterExpression;
        }

        const url = Utils.addQueryParameters(baseUrl, params);
        return this.getElementsFromUrl(url, portalHeader);
    }

    private async getElementsFromUrl(url: string, portalHeader: string): Promise<Page<ViewableOption>> {
        const header = await this.apiClient.getPortalHeader(portalHeader);
        const elements = await this.apiClient.get<PageDTO<ArmAccessResource>>(url, [header])
        return {
            value: elements.value.map(toViewableOption),
            takeNext: elements.nextLink && (() => this.getElementsFromUrl(elements.nextLink, portalHeader)),
            count: elements.count,
        };
    }
}

function containsPattern(searchPattern: string): string | undefined {
    if(searchPattern !== "") {
        const pattern = Utils.encodeURICustomized(searchPattern, Constants.reservedCharTuplesForOData);
        return `contains(properties/displayName,'${pattern}')`;
    }
}


