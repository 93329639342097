import { Toast, ViewManager } from "@paperbits/common/ui";

export class PageAccessRestrictedToastService {

    private toast: Toast | null = null;

    constructor(private readonly viewManager: ViewManager) {
    }

    public openWarning() {
        this.closeWarning();

        this.toast = this.viewManager.addToast(
            "Access restricted",
            "Selected group will not be able to view this page due to access control settings.",
            [{
                title: "Close",
                action: async () => this.closeWarning()
            }]);
    }

    public closeWarning() {
        if(this.toast != null) {
            this.viewManager.removeToast(this.toast);
            this.toast = null;
        }
    }

}