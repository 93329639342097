import { Route, RouteGuard, Router } from "@paperbits/common/routing";
import { PageService } from "../services/pageService";
import { ImpersonationService } from "../services/impersonationService";
import { PageAccessRestrictedToastService } from "../services/pageAccessRestrictedToastService";

export class DesignerAccessRouteGuard implements RouteGuard {

    constructor(
        readonly router: Router,
        readonly pageService: PageService,
        readonly impersonationService: ImpersonationService,
        readonly pageAccessRestrictedToastService: PageAccessRestrictedToastService
    ) {
        this.impersonationService.addImpersonationChangeListener(() => {
            void this.shouldShowWarning(this.router.getCurrentRoute());
        })
    }

    public async canActivate(route: Route): Promise<boolean> {
        await this.shouldShowWarning(route);
        return true;
    }

    private async shouldShowWarning(route: Route) {
        const page = await this.pageService.getPageByPermalink(route.path);
        if (page && !await this.impersonationService.hasAccessTo(page.access)) {
            this.pageAccessRestrictedToastService.openWarning();
        } else {
            this.pageAccessRestrictedToastService.closeWarning();
        }
    }
}