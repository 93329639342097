import { ViewManager } from "@paperbits/common/ui";
import { accessSelectorKey } from "./accessConstants";
import {
    createSecurityModelEditorComponent,
    createStandardVisibilityCommand,
    IVisibilityCommandProvider
} from "@paperbits/core/security/visibilityContextCommandProvider";
import { WidgetContext } from "@paperbits/common/editing";
import { IContextCommand } from "@paperbits/common/ui/IContextCommandSet";

export class AccessPopupSecurityModelEditorProvider implements IVisibilityCommandProvider {

    constructor(
        private readonly viewManager: ViewManager
    ) {
    }

    create(context: WidgetContext): IContextCommand {
        return createStandardVisibilityCommand(() =>
            this.viewManager.openViewAsPopup({
                heading: `Visibility`,
                component: createSecurityModelEditorComponent(context, accessSelectorKey),
                resizing: "vertically horizontally"
            }));
    }
}
