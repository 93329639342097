import * as ko from "knockout";
import template from "./impersonationSelector.html";
import { Component, OnDestroyed, OnMounted } from "@paperbits/common/ko/decorators";
import { ImpersonationService } from "../../services/impersonationService";
import { ViewableOption } from "../../contracts/viewableOption";
import { Utils } from "../../utils";
import { GroupsService } from "../../services/groupsService";

@Component({
    selector: "impersonation-selector",
    template: template
})
export class ImpersonationSelector {

    groups = ko.observableArray<ViewableOption>([]);
    selectedGroup = ko.observable<ViewableOption>({ key: "guests", displayName: "Guests" });

    private readonly subManager = Utils.subscriptionManager();

    constructor(
        private readonly groupsService: GroupsService,
        private readonly impersonationService: ImpersonationService
    ) {
    }

    @OnMounted()
    async onMounted() {
        this.groups(await this.groupsService.getGroups());
        this.selectedGroup(await this.groupsService.guestGroup());
        this.impersonationService.userGroup = this.selectedGroup().key;

        this.subManager.push(this.selectedGroup.subscribe(group => this.impersonationService.userGroup = group.key));
    }

    @OnDestroyed()
    onDestroy() {
        this.subManager.dispose();
    }

    isSelected(option: ViewableOption) {
        return this.selectedGroup().key === option.key;
    }

}