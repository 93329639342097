import { IModelBinder } from "@paperbits/common/editing";
import { SigninSocialModel } from "./signinSocialModel";
import { SigninSocialContract } from "./signinSocialContract";
import { SecurityModelBinder } from "@paperbits/common/security";

export class SigninSocialModelBinder implements IModelBinder<SigninSocialModel> {
    constructor(
        private readonly securityModelBinder: SecurityModelBinder<any, any>
    ) {}

    public async contractToModel(contract: SigninSocialContract): Promise<SigninSocialModel> {
        const model = new SigninSocialModel();

        if (contract.roles) {
            contract.security = {
                roles: contract.roles
            };
        }

        if (contract.security) {
            model.security = await this.securityModelBinder.contractToModel(contract.security);
        }

        model.styles = contract.styles || { appearance: "components/button/default" };
        model.aadLabel = contract.aadLabel || "Azure Active Directory";
        model.aadB2CLabel = contract.aadB2CLabel || "Azure Active Directory B2C";
        model.aadReplyUrl = contract.aadReplyUrl;
        model.aadB2CReplyUrl = contract.aadB2CReplyUrl;

        return model;
    }

    public modelToContract(model: SigninSocialModel): SigninSocialContract {
        return {
            type: "signin-social",
            aadLabel: model.aadLabel,
            aadReplyUrl: model.aadReplyUrl,
            aadB2CLabel: model.aadB2CLabel,
            aadB2CReplyUrl: model.aadB2CReplyUrl,
            styles: model.styles,
            security: model.security
        };
    }
}
