import * as ko from "knockout";
import template from "./accessSecurityModelEditor.html";
import { Component, Param, Event, OnMounted, OnDestroyed } from "@paperbits/common/ko/decorators";
import { ChangeRateLimit } from "@paperbits/common/ko/consts";
import { AccessValuesService, Page } from "../../services/accessValuesService";
import { Utils } from "../../utils";
import { AccessType, Access, EveryoneAccess } from "../../contracts/accessContract";
import { accessSelectorKey } from "./accessConstants";
import { isEqual } from "lodash";
import { ViewableOption } from "../../contracts/viewableOption";
import { AccessSecurityModel } from "../../access/accessSecurityModel";
import { SecurityModelEditor } from "@paperbits/core/security/securityModelEditor";
import { ImpersonationService } from "../../services/impersonationService";

type UiAccessType = AccessType | "all" | "block";
type AccessValueSearchFn = (value: string) => Promise<Page<ViewableOption>>;
type SearchFnByType = { [key in UiAccessType]?: AccessValueSearchFn };
type SearchLabelsByType = { [key in UiAccessType]?: { searchAriaLabel: string; searchAriaResult: string; } };

interface OptionModel extends ViewableOption {
    selected: ko.Observable<boolean>;
}

const typeWithList = new Set<UiAccessType>(["group", "product", "api"]);
const ariaTextsByType: SearchLabelsByType = {
    group: {
        searchAriaLabel: "Search groups",
        searchAriaResult: "Groups"
    },
    product: {
        searchAriaLabel: "Search products",
        searchAriaResult: "Products"
    },
    api: {
        searchAriaLabel: "Search api's",
        searchAriaResult: "Api's"
    }
};

@Component({
    selector: accessSelectorKey,
    template: template
})
export class AccessSecurityModelEditor implements SecurityModelEditor<AccessSecurityModel> {

    @Param()
    header: string = "";

    @Param()
    securityModel: AccessSecurityModel;

    @Event()
    onChange: (contract: AccessSecurityModel) => void;

    selectedAccessType = ko.observable<UiAccessType>("all");
    selectedAccessValues = ko.observableArray<string>([]);

    showList = ko.pureComputed(() => typeWithList.has(this.selectedAccessType()));

    searchPattern = ko.observable("");
    working = ko.observable(false);
    accessValueOptions = ko.observableArray<OptionModel>([]);
    foundValues = ko.observable(0);

    labelsForType = ko.pureComputed(() => ariaTextsByType[this.selectedAccessType()]);
    searchAriaLabel = ko.pureComputed(() => this.labelsForType()?.searchAriaLabel);
    searchAriaResult = ko.pureComputed(() => this.labelsForType()?.searchAriaResult);
    searchPlaceholder = ko.pureComputed(() => `${this.searchAriaLabel()}...`);
    searchResultText = ko.pureComputed(() => `${this.searchAriaResult()} found: ${this.foundValues()}`);
    listAriaLabel = ko.pureComputed(() => `List of ${this.searchAriaResult()}`);

    private takeNextPage: Page<ViewableOption>["takeNext"] | undefined = undefined;

    private searchFnByType: SearchFnByType = {
        group: (value) => this.accessValuesService.getGroups(value),
        product: (value) => this.accessValuesService.getProducts(value),
        api: (value) => this.accessValuesService.getApis(value)
    };

    private subscriptionManager = Utils.subscriptionManager();
    private optionsSubscriptionManager = Utils.subscriptionManager();

    constructor(
        private readonly accessValuesService: AccessValuesService,
        private readonly impersonationService: ImpersonationService
    ) {
    }

    @OnMounted()
    async onMounted() {
        await this.resetData();

        this.subscriptionManager.push(
            this.searchPattern
                .extend(ChangeRateLimit)
                .subscribe(this.search),
            this.selectedAccessType
                .extend(ChangeRateLimit)
                .subscribe(this.onAccessTypeChange),
            this.optionsSubscriptionManager
        );
    }

    @OnDestroyed()
    async onDestroy() {
        this.subscriptionManager.dispose();
    }

    async applyDraft() {
        this.onChange(await this.createContract());
    }

    async resetDraft() {
        await this.resetData();
    }

    async loadNextPage(): Promise<void> {
        if (this.takeNextPage !== undefined) {
            return this.doAsync(async () => {
                const page = await this.takeNextPage();
                this.takeNextPage = page.takeNext;
                this.appendAvailableOptions(page);
            });
        }
    }

    private async resetData() {
        if (isEqual(this.securityModel, EveryoneAccess)) {
            this.selectedAccessType("all");
            this.selectedAccessValues([]);
        } else if (isEqual(this.securityModel, await this.impersonationService.administratorsAccess)) {
            this.selectedAccessType("block");
            this.selectedAccessValues([]);
        } else {
            this.selectedAccessType(this.securityModel.type);
            this.selectedAccessValues([...this.securityModel.allow]);
        }
        await this.search();
    }

    private async onAccessTypeChange(accessType: UiAccessType) {
        if (this.securityModel?.type === accessType && !isEqual(this.securityModel, EveryoneAccess)) {
            this.selectedAccessValues([...this.securityModel.allow]);
        } else {
            this.selectedAccessValues([]);
        }
        await this.search();
    }

    private async search(searchPattern: string = ""): Promise<void> {
        this.optionsSubscriptionManager.dispose();
        this.accessValueOptions([]);

        const accessSearch = this.searchFnByType[this.selectedAccessType()];
        if (accessSearch !== undefined) {
            await this.doAsync(async () => {
                const page = await accessSearch(searchPattern);
                this.foundValues(page.count);
                this.takeNextPage = page.takeNext;
                this.appendAvailableOptions(page);
            });
        }
    }

    private appendAvailableOptions(page: Page<ViewableOption>) {
        const options = this.transformToOptionModel(page.value);
        this.subscribeToOptionsChanges(options);
        this.accessValueOptions.push(...options);
    }

    private transformToOptionModel(contracts: ViewableOption[]): OptionModel[] {
        const selectedKeys = new Set(this.selectedAccessValues());
        const toModel = (contract: ViewableOption): OptionModel => ({
            ...contract,
            selected: ko.observable(selectedKeys.has(contract.key))
        });
        return contracts.map(toModel);
    }

    private subscribeToOptionsChanges(values: OptionModel[]) {
        const subscriptions = values.map(option =>
            option.selected
                .subscribe(selected => {
                    if (selected) {
                        this.selectedAccessValues.push(option.key);
                    } else {
                        this.selectedAccessValues.remove(option.key);
                    }
                })
        );

        this.optionsSubscriptionManager.push(...subscriptions);
    }

    private async createContract(): Promise<Access> {
        const accessType = this.selectedAccessType();
        switch (accessType) {
            case "all":
                return EveryoneAccess;
            case "block":
                return await this.impersonationService.administratorsAccess;
            default:
                return {
                    type: accessType,
                    allow: [...this.selectedAccessValues()]
                };
        }
    }

    private async doAsync(run: () => Promise<void> | void) {
        this.working(true);
        await run();
        this.working(false);
    }

}
