import { SecurityModelBinder } from "@paperbits/common/security/securityModelBinder";
import { AccessContract, EveryoneAccess } from "../contracts/accessContract";
import { AccessSecurityModel } from "./accessSecurityModel";
import { RoleBasedSecurityContract } from "@paperbits/common/security/roleBasedSecurityContract";
import { BuiltInRoles } from "@paperbits/common/user";
import { isNil } from "lodash";
import { ImpersonationService } from "../services/impersonationService";

// Todo: Update SecurityModelBinder interface definition in paperbits-common
// Definition do not support Promise as return type but the code which uses it does.
export interface EnchantedSecurityModelBinder<TSecurityContract, TSecurityModel> {
    contractToModel(contract: TSecurityContract): TSecurityModel | Promise<TSecurityModel>;

    modelToContract(model: TSecurityModel): TSecurityContract | Promise<TSecurityContract>;
}

export class AccessSecurityModelBinder implements EnchantedSecurityModelBinder<AccessContract, AccessSecurityModel> {

    constructor(
        private readonly impersonationService: ImpersonationService
    ) {
    }

    async contractToModel(contract: AccessContract | RoleBasedSecurityContract): Promise<AccessSecurityModel> {
        if (isRoleBasedSecurityContract(contract)) {
            return await this.convertBuildInRolesToAccess(contract.roles);
        }
        return contract;
    }

    modelToContract(model: AccessSecurityModel): AccessContract {
        return model;
    }

    async convertBuildInRolesToAccess(roles: string[]) {
        if (roles.length == 0 || roles.length > 1)
            return await this.impersonationService.administratorsAccess;

        if (roles[0] == BuiltInRoles.everyone.key)
            return EveryoneAccess;

        if (roles[0] == BuiltInRoles.authenticated.key)
            return await this.impersonationService.developersAccess;

        if (roles[0] == BuiltInRoles.anonymous.key)
            return await this.impersonationService.guestsAccess;

        return await this.impersonationService.administratorsAccess;
    }
}

function isRoleBasedSecurityContract(contract: AccessContract | RoleBasedSecurityContract): contract is RoleBasedSecurityContract {
    return !isNil(contract["roles"]);
}
