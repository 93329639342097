import * as ko from "knockout";
import { PageItem as BasePageItem } from "@paperbits/core/workshops/page/ko/pageItem";
import { PageContract } from "../../contracts/pageContract";
import { Access } from "../../contracts/accessContract";

export class PageItem extends BasePageItem {

    access = ko.observable<Access>();

    constructor(page: PageContract) {
        super(page);
        this.access(page.access);
    }

    public toContract(): PageContract {
        return {
            ...super.toContract(),
            access: this.access(),
        };
    }
}
