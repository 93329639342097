import { Access, AccessType, EveryoneAccess } from "../contracts/accessContract";
import { EventManager } from "@paperbits/common/events";
import { GroupsService } from "./groupsService";
import { ViewableOption } from "../contracts/viewableOption";

export class ImpersonationService {

    private impersonatingGroup: string | undefined = undefined;

    private readonly accessToGroupsTransformers: Record<AccessType, (allow: string[]) => Promise<string[]>> = {
        group: async groups => groups,
        product: async products => (await this.groupsService.getProductsGroups(products)).map(value => value.key),
        api: async apis => (await this.groupsService.getApisGroups(apis)).map(value => value.key)
    };

    constructor(
        private readonly eventManager: EventManager,
        private readonly groupsService: GroupsService
    ) {
        void groupsService.guestGroup().then(guest => this.userGroup = guest.key);
    }

    public async hasAccessTo(access: Access) {
        if (access === EveryoneAccess || access === null || !access.type || !(access.allow?.length > 0) || this.impersonatingGroup === (await this.groupsService.administratorsGroup()).key) {
            return true;
        }
        const groups = await this.accessToGroupsTransformers[access.type](access.allow);
        return groups.some(group => group === this.impersonatingGroup);
    }

    public get userGroup(): string | undefined {
        return this.impersonatingGroup;
    }

    public set userGroup(group: string | undefined) {
        this.impersonatingGroup = group;
        this.eventManager.dispatchEvent(onImpersonationChangeEventName, group);
    }

    public addImpersonationChangeListener(listener: (group: string) => void) {
        this.eventManager.addEventListener(onImpersonationChangeEventName, listener);
    }

    public removeImpersonationChangeListener(listener: (group: string) => void) {
        this.eventManager.removeEventListener(onImpersonationChangeEventName, listener);
    }

    public get administratorsAccess(): Promise<Access> {
        return this.groupsService.administratorsGroup().then(toAccess);
    }

    public get developersAccess(): Promise<Access> {
        return this.groupsService.developerGroup().then(toAccess);
    }

    public get guestsAccess(): Promise<Access> {
        return this.groupsService.guestGroup().then(toAccess);
    }
}

const onImpersonationChangeEventName = "onImpersonationChange";

function toAccess(option: ViewableOption): Access {
    return { type: "group", allow: [option.key] };
}
