import { MapiClient } from "../clients";
import { ArmResource } from "../contracts/armResource";
import { toViewableOption, ViewableOption } from "../contracts/viewableOption";
import { uniq } from "lodash";
import { LruCache } from "@paperbits/common/caching";
import { Utils } from "../utils";
import { Logger } from "@paperbits/common/logging";

export class GroupsService {
    private readonly cache: LruCache<ViewableOption[]> = new LruCache<ViewableOption[]>();
    private administrator: ViewableOption | undefined;
    private developer: ViewableOption | undefined;
    private guest: ViewableOption | undefined;

    constructor(private readonly apiClient: MapiClient, private readonly logger: Logger | null = null) {
    }

    async administratorsGroup(): Promise<ViewableOption> {
        if (this.administrator !== undefined)
            return this.administrator;

        const groups = await this.getGroups();
        this.administrator = groups.find(group => group.displayName === "Administrators");
        return this.administrator;
    }

    async developerGroup(): Promise<ViewableOption> {
        if (this.developer !== undefined)
            return this.developer;

        const groups = await this.getGroups();
        this.developer = groups.find(group => group.displayName === "Developers");
        return this.developer;
    }

    async guestGroup(): Promise<ViewableOption> {
        if (this.guest !== undefined)
            return this.guest;

        const groups = await this.getGroups();
        this.guest = groups.find(group => group.displayName === "Guests");
        return this.guest;
    }

    public async getGroups(): Promise<ViewableOption[]> {
        const cachedItems = this.cache.getItem(groupsCacheKey);
        const result = cachedItems ?? await this.fetchGroups();
        return result;
    }

    public async getProductsGroups(names: string[]): Promise<ViewableOption[]> {
        return getMultiple(names, this.getProductGroups.bind(this));
    }

    public async getProductGroups(name: string): Promise<ViewableOption[]> {
        return this.cache.getItem(productCacheKey(name)) ?? await this.fetchProductGroups(name);
    }

    public async getApisGroups(names: string[]): Promise<ViewableOption[]> {
        return getMultiple(names, this.getApiGroups.bind(this));
    }

    public async getApiGroups(name: string): Promise<ViewableOption[]> {
        return this.cache.getItem(apiCacheKey(name)) ?? await this.fetchApiGroups(name);
    }

    private async fetchGroups(): Promise<ViewableOption[]> {
        const response = await this.fetch(`/groups`, "getGroups");
        const groups = response.map(toViewableOption);
        this.cache.setItem(groupsCacheKey, groups);
        return groups;
    }

    private async fetchProductGroups(name: string): Promise<ViewableOption[]> {
        const response = await this.fetch(`/products/${name}/groups`, "getProductGroups");
        const groups = response.map(toViewableOption);
        this.cache.setItem(productCacheKey(name), groups);
        return groups;
    }

    private async fetchApiGroups(name: string): Promise<ViewableOption[]> {
        const response = await this.fetch(`/apis/${name}/products`, "getApiProducts");
        const productNames = response.map(product => product.name);
        const groups = await this.getProductsGroups(productNames);
        this.cache.setItem(apiCacheKey(name), groups);
        return groups;
    }

    private async fetch(url: string, eventName: string) {
        const header = await this.apiClient.getPortalHeader(eventName);
        url = Utils.addQueryParameters(url, { ["skipWorkspaces"]: "true" });

        return this.apiClient.getAll<ArmResource>(url, [header]);
    }
}

export interface IGroupsService {
    getGroups(): Promise<ViewableOption[]>;

    getProductsGroups(names: string[]): Promise<ViewableOption[]>

    getProductGroups(name: string): Promise<ViewableOption[]>

    getApisGroups(names: string[]): Promise<ViewableOption[]>

    getApiGroups(name: string): Promise<ViewableOption[]>
}

const groupsCacheKey = "groups/all";

function productCacheKey(name: string) {
    return `product/${name}`;
}

function apiCacheKey(name: string) {
    return `api/${name}`;
}

async function getMultiple(names: string[], fetchFn: (name: string) => Promise<ViewableOption[]>): Promise<ViewableOption[]> {
    const viewableOptions = await Promise.all(names.map(fetchFn));
    return uniq(viewableOptions.flat());
}