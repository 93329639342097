import * as Constants from "../constants";
import { ISettingsProvider } from "@paperbits/common/configuration";
import { HttpClient } from "@paperbits/common/http";
import { KnownHttpHeaders } from "../models/knownHttpHeaders";
import { ServiceDescriptionContract } from "../contracts/service";
import { SettingNames } from "../constants";
import { ArmAuthenticator } from "../authentication/armAuthenticator";
import { Logger } from "@paperbits/common/logging/logger";

export class AzureResourceManagementService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly authenticator: ArmAuthenticator,
        private readonly logger: Logger
    ) { }

    /**
     * Returns API Management service description.
     */
    public async getServiceDescription(managementApiUrl: string): Promise<ServiceDescriptionContract> {
        const armAccessToken = await this.authenticator.getAccessTokenAsString();

        const serviceDescriptionResponse = await this.httpClient.send<ServiceDescriptionContract>({
            url: `${managementApiUrl}?api-version=${Constants.managementApiVersion}`,
            headers: [{
                name: KnownHttpHeaders.Authorization,
                value: armAccessToken
            }]
        });
        const service = serviceDescriptionResponse.toObject();
        this.logger.trackEvent("ArmService", { message: `Service: ${service.name} - SKU: ${service.sku.name}` });
        return service;
    }

    /**
     * Returns user-sepcific access token.
     * @param userName {string} User Identitfier, e.g. johndoe.
     */
    public async getUserAccessToken(userName: string, managementApiUrl: string): Promise<string> {
        const armAccessToken = await this.authenticator.getAccessTokenAsString();
        const exp = new Date(new Date().valueOf() + 60 * 60 * 1000);

        const userTokenResponse = await this.httpClient.send<ServiceDescriptionContract>({
            url: `${managementApiUrl}/users/${userName}/token?api-version=${Constants.managementApiVersion}`,
            method: "POST",
            headers: [{
                name: KnownHttpHeaders.Authorization,
                value: armAccessToken
            },
            {
                name: KnownHttpHeaders.ContentType,
                value: "application/json"
            }],
            body: JSON.stringify({
                keyType: "primary",
                expiry: exp.toISOString()
            })
        });

        const userToken = userTokenResponse.toObject();
        const userTokenValue = userToken["value"];

        return userTokenValue;
    }

    public async loadSessionSettings(settingsProvider: ISettingsProvider): Promise<void> {
        const armEndpoint = await this.getStoredSettingAsync(SettingNames.armEndpoint, settingsProvider);
        const armUri = await this.getTenantArmUriAsync(settingsProvider);

        if (!armUri || !armEndpoint) {
            throw new Error("Required service parameters (like subscription, resource group, service name, tenant Id) were not provided to start editor");
        }

        if (armUri) {
            const managementApiUrl = `https://${armEndpoint}${armUri}`;
            await settingsProvider.setSetting(SettingNames.managementApiUrl, managementApiUrl);
            this.logger.trackEvent("ArmService", { message: `Management API URL: ${managementApiUrl}` });

            const url = this.resolveCurrentUrl();
            if(url?.searchParams.has(SettingNames.subscriptionId.toLowerCase())) {
                location.href = location.origin + location.pathname;
                this.logger.trackEvent("ArmService", { message: `Location replaced` });
            }
        }
    }

    public async getTenantArmUriAsync(settingsProvider: ISettingsProvider): Promise<string> {
        const subscriptionId = await this.getStoredSettingAsync(SettingNames.subscriptionId, settingsProvider);
        const resourceGroupName = await this.getStoredSettingAsync(SettingNames.resourceGroupName, settingsProvider);
        const serviceName = await this.getStoredSettingAsync(SettingNames.serviceName, settingsProvider);
        const authTenantId = await this.getStoredSettingAsync(SettingNames.authTenantId, settingsProvider);

        if (!subscriptionId || !resourceGroupName || !serviceName || !authTenantId) {
            throw new Error("Required service parameters (like subscription, resource group, service name, tenant Id) were not provided to start editor");
        }

        return `/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/providers/Microsoft.ApiManagement/service/${serviceName}`;
    }


    private async getStoredSettingAsync(settingName: string, settingsProvider: ISettingsProvider): Promise<string> {
        const settingKey = settingName.toLowerCase();
        const url = this.resolveCurrentUrl();
        let settingValue = url?.searchParams.get(settingKey) || await settingsProvider.getSetting(settingName);
        if (url) {
            if (settingValue) {
                settingValue = decodeURIComponent(settingValue);
                sessionStorage.setItem(settingKey, settingValue);
            } else {
                settingValue = sessionStorage.getItem(settingKey);
            }
        }
        return settingValue;
    }

    private resolveCurrentUrl(): URL | null {
        if (location && location.href) {
            return new URL(location.href.toLowerCase());
        }

        this.logger.trackEvent("ArmService", { message: "Location is not available" });
        return null;
    }
}