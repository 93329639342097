import * as ko from "knockout";
import template from "./pageDesignProperties.html";
import { Component, Param, OnMounted, OnDestroyed, Event } from "@paperbits/common/ko/decorators";
import { PageItem } from "./pageItem";
import { IPageService } from "../../contracts/pageContract";
import { IMediaService, MediaContract } from "@paperbits/common/media";
import { BackgroundModel } from "@paperbits/common/widgets/background";
import { Router } from "@paperbits/common/routing";
import { ViewManager } from "@paperbits/common/ui";
import { ISettingsProvider } from "@paperbits/common/configuration";
import { ILocaleService } from "@paperbits/common/localization";
import { EventManager } from "@paperbits/common/events";
import { Utils } from "../../utils";

@Component({
    selector: "page-design-properties-workshop",
    template: template
})
export class PageDesignPropertiesWorkshop {

    @Param()
    public pageItem: PageItem;

    @Event()
    private readonly onDeleteCallback: () => void;

    @Event()
    private readonly onCopyCallback: (pageItem: PageItem) => void;

    public readonly isReserved = ko.observable(false);
    public readonly isSeoEnabled = ko.observable(false);
    public readonly socialShareImage = ko.observable<BackgroundModel>();

    private subscriptionManager = Utils.subscriptionManager();

    constructor(
        private readonly pageService: IPageService,
        private readonly router: Router,
        private readonly viewManager: ViewManager,
        private readonly reservedPermalinks: string[],
        private readonly settingsProvider: ISettingsProvider,
        private readonly mediaService: IMediaService,
        private readonly localeService: ILocaleService,
        private readonly eventManager: EventManager
    ) {
    }


    @OnMounted()
    public async onMounted(): Promise<void> {
        this.subscriptionManager.push(
            this.pageItem.title
                .extend(<any>{ required: true, onlyValid: true })
                .subscribe(this.applyChanges),
            this.pageItem.description
                .subscribe(this.applyChanges),
            this.pageItem.keywords
                .subscribe(this.applyChanges),
            this.pageItem.jsonLd
                .subscribe(this.applyChanges),
        );

        let validPermalink = this.pageItem.permalink;
        if (this.reservedPermalinks.includes(validPermalink())) {
            this.isReserved(true);
        } else {
            validPermalink = validPermalink.extend(<any>{ required: true, isValidPermalink: true, isPermalinkInUse: this.pageItem.key, onlyValid: true });
         
            this.subscriptionManager.push(validPermalink.subscribe(this.onPermalinkChange));
        }
        await this.router.navigateTo(validPermalink());

        const socialShareData = this.pageItem.socialShareData();

        if (socialShareData?.image?.sourceKey) {
            const media = await this.mediaService.getMediaByKey(socialShareData.image.sourceKey);
            if (media) {
                const imageModel = new BackgroundModel();
                imageModel.sourceUrl = media.downloadUrl;
                this.socialShareImage(imageModel);
            }
        }

        const locale = await this.localeService.getCurrentLocaleCode();
        const defaultLocale = await this.localeService.getDefaultLocaleCode();
        if (locale !== defaultLocale) {
            this.isReserved(true);
        }

        const seoSetting = await this.settingsProvider.getSetting<boolean>("features/seo");
        if (seoSetting) {
            this.isSeoEnabled(seoSetting);
        }

        this.viewManager.setHost({ name: "page-host" });
        this.eventManager.dispatchEvent("displayHint", {
            key: "41d9",
            content: `If you change the permalink of a page, all hyperlinks pointing to it will be automatically updated everywhere on your website.`
        });
    }

    @OnDestroyed()
    public async onDestroyed() {
        this.subscriptionManager.dispose();
    }

    private async applyChanges(): Promise<void> {
        await this.pageService.updatePage(this.pageItem.toContract());
    }

    private async onPermalinkChange(): Promise<void> {
        const permalink = this.pageItem.permalink();
        this.router.updateHistory(permalink, this.pageItem.title());

        await this.applyChanges();
    }

    public async onMediaSelected(media: MediaContract): Promise<void> {
        let socialShareData = null;

        if (media) {
            socialShareData = {
                image: {
                    sourceKey: media.key,
                },
            };

            const imageModel = new BackgroundModel();
            imageModel.sourceUrl = media.downloadUrl;
            this.socialShareImage(imageModel);
        }
        else {
            this.socialShareImage(null);
        }

        this.pageItem.socialShareData(socialShareData);

        await this.applyChanges();
    }

    public async deletePage(): Promise<void> {
        await this.pageService.deletePage(this.pageItem.toContract());

        if (this.onDeleteCallback !== undefined) {
            this.onDeleteCallback();
        }

        this.router.navigateTo("/");
    }

    public async copyPage(): Promise<void> {
        const copiedPage = await this.pageService.copyPage(this.pageItem.key);

        if (this.onCopyCallback !== undefined) {
            this.onCopyCallback(new PageItem(copiedPage));
        }
    }

}
