import { IInjectorModule, IInjector } from "@paperbits/common/injection";
import { PageDesignPropertiesWorkshop } from "./pageDesignProperties";
import { PageDetailsWorkshop } from "./pageDetails";
import { PagesWorkshop } from "./pages";


export class PageDesignModule implements IInjectorModule {
    public register(injector: IInjector): void {
        injector.bind("pageDesignPropertiesWorkshop", PageDesignPropertiesWorkshop);

        // Paperbits Overrides
        injector.bind("pagesWorkshop", PagesWorkshop);
        injector.bind("pageDetailsWorkshop", PageDetailsWorkshop);
    }
}