import * as ko from "knockout";
import { IComponent, ToolButton } from "@paperbits/common/ui";

export class ImpersonationToolButton implements ToolButton {
    public iconClass: string = "paperbits-icon paperbits-icon paperbits-single-02";
    public title: string = "Impersonate";
    public tooltip: string = `<h1>Impersonate</h1><p>See how the content looks like<br/> for users in a particular group.</p>`;
    public disabled = ko.observable(false);

    public component: IComponent = {
        name: "impersonation-selector",
    }
}